<template>

  <div class="text-right">
    <v-list-item style="background-color: #1a1927">

      <v-list-item-avatar>
        <img :src="comment.image?$store.state.smallthumbnail+comment.image:'/img/icons/android-chrome-192x192.png'"/>
      </v-list-item-avatar>
      <v-list-item-content style="text-align: justify">
        <v-list-item-title style="color: #bdbdc2">{{comment.fullName}}

          <v-rating dense readonly size="15" :value="parseInt(comment.rate)"></v-rating>
          <small v-if="comment.confirm<1" style="color: indianred">این نظر هنوز تایید نشده است


            <v-tooltip left color="red">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" x-small :loading="loading" icon color="red"
                       @click="deleteCm(comment.id)">
                  <v-icon>{{icon.mdiDeleteForeverOutline }}</v-icon>
                </v-btn>
              </template>
              <small>
                حذف نظر
              </small>
            </v-tooltip>
          </small>
        </v-list-item-title>
        <span style="color: #bdbdc2" >{{comment.text}}</span>

        <small style="color: #bdbdc2">{{comment.time|momentDate}}</small>

        <div :class="[response&&$store.state.openedComment===comment.id?'red--text':'green--text']">

          <template v-if="response&&$store.state.openedComment===comment.id">
            <v-btn @click="openResponse" icon color="red"><v-icon>{{icon.mdiCommentRemoveOutline }}</v-icon></v-btn>
            <span style="cursor: pointer" @click="openResponse"> بستن</span>
          </template>
          <template v-else >
            <v-btn @click="openResponse" icon color="green"><v-icon>{{icon.mdiReply}}</v-icon></v-btn>
            <span style="cursor: pointer" @click="openResponse">
                        افزودن پاسخ
                        </span>
          </template>
        </div>



        <div class="comments" v-if="response&&$store.state.openedComment===comment.id">
          <h3>پاسخ به نظر
            {{comment.fullName}}
          </h3>
          <v-rating v-model="rate" hover color="white" dense></v-rating>
          <small>امتیاز
            {{rate}}
            از
            5
          </small>
          <v-textarea
              filled
              dense
              dark
              v-model="text"
              :append-outer-icon="icon.mdiComment"
              name="input-10-1"
              label="افزودن نظر"
              :loading="loading"
          >
            <template v-slot:prepend>
              <v-btn icon @click="send(comment.id)" :loading="loading">
                <v-icon>
                  {{icon.mdiSend}}
                </v-icon>
              </v-btn>
            </template>


          </v-textarea>
        </div>



      </v-list-item-content>
      <v-list-item-action class="green--text align-center">
        <v-btn small @click="like(comment)" color="green" icon>
          <v-icon small>{{icon.mdiThumbUp }}</v-icon>
        </v-btn>
        {{comment.like?('+'+comment.like):'0'}}
      </v-list-item-action>
      <v-list-item-action class="align-center red--text">
        <v-btn small color="red" @click="dislike(comment)" icon>
          <v-icon small>{{icon.mdiThumbDown }}</v-icon>
        </v-btn>
        {{comment.dislike?('+'+comment.dislike):'0'}}
      </v-list-item-action>




    </v-list-item>
  </div>
</template>

<script>

import {VRating, VTextarea, VIcon} from 'vuetify/lib'
import {mdiComment, mdiSend, mdiDeleteForeverOutline, mdiThumbUp, mdiThumbDown,mdiReply,mdiCommentRemoveOutline  } from '@mdi/js'

import axios from "axios";
import moment from "jalali-moment";

export default {
  computed: {
    loggedIn() {
      return this.$store.state.userId;
    },
  },

  props: ['comment'],
  filters: {
    momentDate(date) {
      return moment.unix(date).locale('fa').format('jYYYY/jMM/jDD LT');
    },
    momentDay(date) {
      return moment.unix(date).locale('fa').format('dddd');
    },
  },
  data() {
    return {
      loading: false,
      response: false,
      data: {},
      items:null,
      icon: {mdiComment, mdiSend, mdiDeleteForeverOutline, mdiThumbUp, mdiThumbDown,mdiReply,mdiCommentRemoveOutline },
      rate: 3,
      text: "",
      id: this.$route.params.id,
    }
  },

  components: {
    VRating, VTextarea, VIcon
  },

  methods: {

    like(comment) {
      if (!localStorage.getItem('lk' + comment.id)) {
        localStorage.setItem('lk' + comment.id, 1);
        comment.like++;
        axios.post(this.$store.state.api + 'likecomment', {
          id: comment.id,
        }).then(response => {

          response.data.result;
        })
      } else {
        this.$store.state.notify.text = 'قبلا امتیاز دادید';
        this.$store.state.notify.is = true;
      }
    },
    dislike(comment) {
      if (!localStorage.getItem('lk' + comment.id)) {
        comment.dislike++;
        localStorage.setItem('lk' + comment.id, 0);
        axios.post(this.$store.state.api + 'dislikecomment', {
          id: comment.id,
        }).then(response => {
          response.data.result;
        })
      } else {
        this.$store.state.notify.text = 'قبلا امتیاز دادید';
        this.$store.state.notify.is = true;
      }
    },


    send(ansId = null) {
      if (!this.loggedIn) {
        this.$store.state.loginDialog = true;
      } else {
        if (this.$store.state.userId) {
          this.loading = true;
          axios.post(this.$store.state.api + 'addpostcomment', {
            postId: this.id,
            userId: this.$store.state.userId,
            ansId: ansId,
            rate: this.rate,
            text: this.text
          }).then(response => {
            this.loading = false;
            this.text = "";
            this.rate = 3;
            this.$store.state.notify.text = 'نظر ثبت شد پس از تایید مدیر نمایش داده میشود';
            this.$store.state.notify.is = true;
            this.items = response.data.data;
            this.setData();
            this.response=false;
          })
        }
      }
    },
    deleteCm(id) {
      this.loading = true;
      axios.post(this.$store.state.api + 'delpostcomment', {
        id: id,
        postId: this.id,
        userId: this.$store.state.userId,
      }).then(response => {
        this.loading = false;
        this.$store.state.notify.text = 'نظر حذف شد';
        this.$store.state.notify.is = true;
        this.items = response.data.data;
        this.setData();
      })
    }
    ,
    openResponse(){
      if(this.$store.state.openedComment!==this.comment.id){
        this.response=true;
      }else {
        this.response=!this.response;
      }
      this.$store.state.openedComment=this.comment.id;
    }
    ,
    setData() {
      this.$emit('setDataevent', this.items);
    },
  },




}
</script>

<style scoped>

.menuItem {
  width: 300px;
  display: flex;
  justify-content: right;
  cursor: pointer;
  text-align: right;
  margin-top: 20px;
}

.singlecomment {
  border-bottom: 1px #ccc solid !important;
  margin-bottom: 10px;
}

.response {
  margin-right: 50px;
  border-radius: 10px;
  background-color: #202036 !important;
  color: #fff;
}

.singlecomment:last-child {
  border-bottom: none !important;
}

.comments {
  background: #202036; /* fallback for old browsers */
  border-radius: 10px;
  /*background: -webkit-linear-gradient(to right, #2f2f40, #191926); !* Chrome 10-25, Safari 5.1-6 *!
  background: linear-gradient(to right, #2f2f40, #191926); !* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ *!*/

  padding: 20px;
  color: #cccccc;
}

.menuIcon {
  height: 50px;
  margin: -5px 10px 10px 10px;
}

.items {
  position: relative;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  margin: 20px 15px;
}

.btn-service {
  position: absolute;
  bottom: -20px;
  left: 20px;
}

.menutext {
  display: inline-block;
}

.textfooter {
  display: block;
  font-size: 12px;
}

.deletecm {
  color: red;
  cursor: pointer;

}

.deletecm:hover {
  color: #670f0f;
  text-shadow: 0px 0px 1px #aa1515;
}

</style>