<template>
  <div>
    <v-container class="main-container d-flex flex-wrap">
      <div class="col-12 col-mm-8 pa-1">
        <TopContent></TopContent>
        <div class="mt-4" >
<!--          <p class="ma-0 white&#45;&#45;text" style="margin-top: 3px">در : </p>-->
          <v-breadcrumbs
              divider=">"
              large
              style="color: white;background-color: #2c2a42;border-radius: 20px"
              :items="address"
          >
            <template v-slot:item="{ item }">
              <router-link :to="{name:'posts',params:{id:item.id,title:item.title}}" custom v-slot="{navigate,href}">
              <v-breadcrumbs-item
                  :href="href"
                  @click="navigate"
              >
                <span style="color: #74beff" class="breadcrumbs">{{ item.title }}</span>
              </v-breadcrumbs-item>
              </router-link>
            </template>

          </v-breadcrumbs>
        </div>

        <div :class="{'postloading':pageloading}" ref="topdiv" class="posts-card d-flex posts-card__wrapresponsive">
          <div class="posts-card__img">
            <img :src="$store.state.thumbnail + post.image" class="posts-card__img"/>
          </div>
          <div class="mr-3">
            <h2 class="white--text my-2 font-weight-regular" style="font-size: 22px">{{post.title}}</h2>
            <div class="posts-card__desc">
              <router-link :to="{name:'posts',params:{id:post.catId,title:post.catTitle}}" custom v-slot="{navigate,href}">
              <p class="mb-2">در دسته ی <a :href="href" @click="navigate" role="link" style="color: #568eff">{{post.catTitle}}</a></p>
              </router-link>
<!--              <p class="mb-2">نویسنده: {{ post.fName }} {{ post.lName }}</p>-->
              <p class="mb-2">تاریخ انتشار : {{ post.createTime | momentDate }}</p>
              <p class="mb-2">آخرین ویرایش : {{ post.updateTime | momentHour }}</p>
              <div class="my-10"> </div>
<!--              <p class="my-6" style="color: #7a7a7a" ><span style="color: #d1d1d1">مدت زمان</span>:34:دقیقه</p>-->
              <p style="text-align: justify;text-justify: inter-word;color: #7a7a7a" ><span style="color: #d1d1d1">توضیحات کوتاه </span> : {{post.desc}}</p>

            </div>
          </div>
        </div>

        <p class="side-content__title">توضیحات</p>
        <div class="center-content">
          <div v-html="post.fullDesc" style="margin: 16px 16px 20px 16px; font-size: 18px;color: #b6b6b6;text-align: justify;text-justify: inter-word;">
          </div>
        </div>

        <div ref="videoshow"></div>

<!--        <vue-plyr class="my-10" v-if="videofiles">
          <video poster="../assets/images/mehranmedia.png">
            <source :src="videofiles.url" type="video/mp4" >
&lt;!&ndash;            <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" :size="parseInt(videofiles.quality)">&ndash;&gt;
            &lt;!&ndash;            <track kind="captions" label="English" srclang="en" src="captions-en.vtt" default>&ndash;&gt;
          </video>
        </vue-plyr>-->

        <video class="my-10" v-if="videofiles" width="100%" controls>
          <source :src="videofiles.url" type="video/mp4">
          Your browser does not support HTML video.
        </video>





        <p v-if="fileSets.length>0" class="side-content__title">مشاهده آنلاین و دانلود</p>


        <div v-for="(fileSet,i) in fileSets" :key="i + 'fileset'" class="center-content pa-0 mt-2 mb-6">

          <div class="filesettitle d-flex align-center justify-space-between">
            <div class="d-flex align-center">
            <p class="ma-0">{{fileSet.fileSet.title}}</p>
            <p class="filesettitle--quality ma-0 mr-2">{{fileSet.fileSet.quality}}</p>
            </div>
            <v-icon v-if="!fileSet.free && !premiumTime" x-large color="white">mdi-lock</v-icon>
          </div>

          <div v-for="file in fileSet.files" :key="file.id">
          <div class="filestitle d-flex align-center justify-space-between flex-wrap">
            <p class="my-1" style="color: #9f9f9f" >{{file.title}}</p>

            <p class="files--download my-1 ml-2 py-2" style="background-color: #2b2b3c;font-size: 16px !important;">{{file.size}} مگابایت</p>

            <div class="d-flex align-center">
              <div v-if="file.type=='video'" class="ml-2">
            <a v-if="file.free || premiumTime" class="files--download my-1" style="background-color: #049cbd" @click="showVideo(file)">نمایش</a>
                <a v-else class="files--download my-1" style="background-color: #049cbd">
                  <router-link v-if="profile" :to="{name:'permiumuser'}" custom v-slot="{ navigate , href }">
                    <a :href="href" @click="navigate" role="link"><span class="white--text" ><v-icon color="white">mdi-lock</v-icon> نمایش</span></a>
                  </router-link>
                  <router-link v-else :to="{name:'signin'}" custom v-slot="{ navigate , href }">
                    <a :href="href" @click="navigate" role="link"><span class="white--text"><v-icon color="white">mdi-lock</v-icon> نمایش</span></a>
                  </router-link>
                </a>
              </div>
            <a v-if="file.free || premiumTime" :href="file.url" class="files--download my-1"> دانلود</a>
            <a v-else class="files--download my-1">
              <router-link v-if="profile" :to="{name:'permiumuser'}" custom v-slot="{ navigate , href }">
                <a :href="href" @click="navigate" role="link"><span class="white--text" ><v-icon color="white">mdi-lock</v-icon> دانلود</span></a>
              </router-link>
              <router-link v-else :to="{name:'signin'}" custom v-slot="{ navigate , href }">
                <a :href="href" @click="navigate" role="link"><span class="white--text"><v-icon color="white">mdi-lock</v-icon> دانلود</span></a>
              </router-link>
            </a>
            </div>

          </div>

            <div v-if="file.desc.length>0" class="filesdesc">
              <p class="mr-2">{{ file.desc }}</p>
            </div>

        </div>

        </div>

        <div v-if="!premiumTime && profile && fileSets.length>0" class="center-content mt-2 mb-5" style="background-color: #7a0026;text-align: justify;text-justify: inter-word;">
          <div style="margin: 8px 24px; font-size: 24px;color: white">
            <p style="font-size: 30px">هنوز عضو ویژه نشده اید؟</p>
            <router-link :to="{name:'permiumuser'}" custom v-slot="{ navigate , href }">
            <p style="color: #b6b6b6;">برای دریافت فایل ها و مشاهده آنلاین <a
                :href="href" @click="navigate" role="link"><span style="text-decoration: underline #faef45;color: #faef45;cursor: pointer;">عضو ویژه</span></a> شوید</p>
            </router-link>
          </div>
        </div>

        <div class="center-content mt-2 mb-10" style="padding: 18px 24px 12px 24px; font-size: 24px;background-color: #003063;text-align: justify;text-justify: inter-word;">
          <p style="font-size: 30px" class="yellow--text"> <v-icon x-large color="yellow">mdi-hand-pointing-left</v-icon> ترافیک نیم بها</p>
          <p class="white--text">ترافیک اینترنت برای تماشا یا دانلود فیلم در مهران مدیا بر روی تمامی اپراتورها به صورت <span class="yellow--text" >نیم‌ بها</span> محاسبه خواهد شد. (هنگام تماشا و دانلود وی پی ان خود را حتما خاموش کنید)</p>
        </div>

        <PostComment v-if="postrender" class="my-10" ></PostComment>


      </div>
      <div class="col-12 col-mm-4 py-6 px-lg-6 px-2">

        <LiveSearch></LiveSearch>

        <LastPosts></LastPosts>

        <RelatedPosts></RelatedPosts>

      </div>
    </v-container>
  </div>
</template>

<script>
import TopContent from "@/components/TopContent";
import LastPosts from "../components/LastPosts";
import RelatedPosts from "../components/RelatedPosts";
import LiveSearch from "../components/LiveSearch";
import PostComment from "../components/PostComment";


import axios from "axios";
import {momentfilter} from "../plugins/moment";
import moment from "jalali-moment";
export default {
  name: 'Home',
  mixins : [momentfilter],
  data() {
    return {
      id : this.$route.params.id,
      post : [],
      premiumTime : false,
      fileSets : [],
      profile : null,
      address : [],
      pageloading : false,
      postrender : false,
      videofiles : null,
    }
  },
  components: {
    TopContent,
    LastPosts,
    RelatedPosts,
    LiveSearch,
    PostComment,
  },
  methods : {
    getPerimium(date){
      var a = moment(new Date());
      var b = moment.unix(date);
      if(b.diff(a)>=0){
        this.premiumTime = true;
      } else {
        this.premiumTime = false;
      }
    },
    showVideo(files){
      this.videofiles = files;
      this.goto('videoshow');
    },
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo({top: top,
        left: 0,
        behavior: 'smooth'});
    }
  },
  created() {

    axios.get(this.$store.state.api + 'singlepost',{params:{
        userId : this.$store.state.userId,
        id : this.$route.params.id,
      }
    }).then(response => {
      this.post = response.data.data.item;
      this.address = response.data.data.address;
      this.fileSets = response.data.data.fileSets;
      this.postrender = true;
    })

    axios.post(this.$store.state.api + 'getprofile',{
      userId : this.$store.state.userId
    }).then(response=>{
      this.profile = response.data.data;
      if(this.profile && this.profile.premiumTime){
        this.getPerimium(this.profile.premiumTime);
      }


    })
  },
  metaInfo() {
    return {
      title: this.post.title
      ,link: [{rel: 'canonical', href: this.$store.state.url+'/posts/single/'+this.id+'/'+this.post.title}],
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.post.desc,
        },
        {
          property: 'og:description',
          content: this.post.desc,
          vmid: 'og:description'
        },

        {
          property: 'og:image',
          vmid: 'og:image',
          content: this.$store.state.thumbnail + this.post.image
        }, {
          property: 'og:title',
          vmid: 'og:title',
          content: 'مهران مدیا' + '::' + this.post.title
        }
      ],
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.goto('topdiv');
      this.pageloading = true;
      this.videofiles = null,
      this.postrender = false;
      axios.get(this.$store.state.api + 'singlepost',{params:{
          userId : this.$store.state.userId,
          id : this.$route.params.id,
        }
      }).then(response => {
        this.post = response.data.data.item;
        this.fileSets = response.data.data.fileSets;
        this.address = response.data.data.address;
        this.postrender = true;
        this.pageloading = false;
      })
    }
  }
}
</script>

<style>

.theme--light.v-breadcrumbs .v-breadcrumbs__divider, .theme--light.v-breadcrumbs .v-breadcrumbs__item--disabled {
  color : #959595;
}

.filesettitle{
  background-color: #bd047e;
  min-height: 70px;
  border-radius: 20px 20px 0 0;
  color: white;
  font-size: 20px;
  padding: 0 24px 0 16px;
  user-select: none;
}

.filestitle{
  background-color: #323246;
  min-height: 55px;
  padding: 0 24px 0 16px;
  color: white;
  font-size: 18px;

  user-select: none;
}

.filesdesc {
  border-bottom: 5px solid #2b2b3c;
  padding: 0 24px 0 16px;
  color: #999999;
  background-color: #323246;
  font-size: 12px;
}

.files--download{
  background-color: #bd047e;
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 31px;
  border-radius: 40px;
  padding: 2px 24px 2px 24px ;
  color:#fff !important;
  text-decoration: none !important;
}



.filesettitle--quality{
  background-color: #fff;
  font-size: 16px;
  border-radius: 40px;
  padding: 4px 24px 0px 24px ;
  color:#bd047e;
}

.breadcrumbs:hover{
  color: #bbbbbb !important;
}


</style>