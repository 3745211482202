<template>

  <div class="text-right">
    <template>
      <div class="comments">
        <h3 style="font-size: 24px;font-weight: normal" >نظرات</h3>
        <v-rating v-model="rate" hover color="white" dense></v-rating>
        <small>امتیاز
          {{rate}}
          از
          5
        </small>
        <v-textarea
            filled
            dense
            dark
            v-model="text"
            :append-outer-icon="icon.mdiComment"
            name="input-10-1"
            label="افزودن نظر"
            :loading="loading"
        >
          <template v-slot:prepend>
            <v-btn icon @click="send()" :loading="loading">
              <v-icon>
                {{icon.mdiSend}}
              </v-icon>
            </v-btn>
          </template>


        </v-textarea>
      </div>
      <v-list three-line>


        <v-skeleton-loader :loading="!items" type="list-item-avatar-two-line@5">
          <div>
            <div v-for="(comment,index) in items" :key="index" class="singlecomment" >
              <SinglePostComment @setDataevent="items=$event" :comment="comment.parent"></SinglePostComment>
              <div v-for="(res,index) in comment.response" :key="index">
                <div  class="response singlecomment" >
                  <v-list-item>
                    <v-list-item-avatar>
                      <img :src="res.image?$store.state.smallthumbnail+res.image:'/img/icons/android-chrome-192x192.png'"/>
                    </v-list-item-avatar>

                    <v-list-item-content style="text-align: justify">
                      <v-list-item-title style="color: #bdbdc2" >{{res.fullName}}
                        <v-rating dense readonly size="15" :value="res.rate"></v-rating>
                        <small v-if="res.confirm<1" style="color: indianred">این نظر هنوز تایید نشده است
                          <v-tooltip  left color="red">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" x-small :loading="loading" icon color="red" @click="deleteCm(res.id)" ><v-icon>{{icon.mdiDeleteForeverOutline }}</v-icon></v-btn>
                            </template>
                            <small>
                              حذف نظر
                            </small>
                          </v-tooltip>
                        </small>
                      </v-list-item-title>
                      <span style="color: #bdbdc2" >{{res.text}}</span>

                      <small style="color: #bdbdc2" >{{res.time|momentDate}}</small>
                    </v-list-item-content>
                    <v-list-item-action class="green--text align-center"><v-btn x-small @click="like(res)" color="green" icon><v-icon x-small>{{icon.mdiThumbUp }}</v-icon></v-btn>{{res.like?('+'+res.like):'0'}}</v-list-item-action>
                    <v-list-item-action class="align-center red--text"><v-btn x-small color="red" @click="dislike(res)" icon><v-icon x-small>{{icon.mdiThumbDown }}</v-icon></v-btn>{{res.dislike?('+'+res.dislike):'0'}}</v-list-item-action>
                  </v-list-item>
                </div>
              </div>
            </div>
          </div>
        </v-skeleton-loader>

        <div style="padding: 10px;color: #bdbdc2" v-if="!(items.length>0)">
          <v-icon style="color: #bdbdc2">{{icon.mdiComment}}</v-icon>
          هیچ نظری فعلا ثبت نشده است اولین نظر شما باشید!
        </div>


      </v-list>

    </template>
  </div>
</template>

<script>

import { VRating,VTextarea,VIcon } from 'vuetify/lib'
import {mdiComment,mdiSend,mdiDeleteForeverOutline,mdiThumbUp,mdiThumbDown  } from '@mdi/js'

import axios from "axios";
import moment from "jalali-moment";
import SinglePostComment from "@/components/SinglePostComment";
export default {
  computed: {
    loggedIn() {
      return this.$store.state.userId;
    },
  },
  filters:{
    momentDate(date){
      return moment.unix(date).locale('fa').format('jYYYY/jMM/jDD LT');
    },
    momentDay(date){
      return moment.unix(date).locale('fa').format('dddd');
    },
  },
  data() {
    return {
      loading:false,
      items: [],
      data: {},
      icon: {mdiComment,mdiSend,mdiDeleteForeverOutline,mdiThumbUp,mdiThumbDown  },
      rate:3,
      text:"",
      id: this.$route.params.id,
    }
  },

  components:{
    SinglePostComment,
    VRating,VTextarea,VIcon
  },

  methods:{

    like(comment){
      if(!localStorage.getItem('lk'+comment.id)){
        localStorage.setItem('lk'+comment.id,1);
        comment.like++;
        axios.post(this.$store.state.api + 'likecomment', {
          id: comment.id,
        }).then(response => {

          response.data.result;
        })
      }else{
        this.$store.state.notify.text = 'قبلا امتیاز دادید';
        this.$store.state.notify.is = true;
      }
    },
    dislike(comment){
      if(!localStorage.getItem('lk'+comment.id)){
        comment.dislike++;
        localStorage.setItem('lk'+comment.id,0);
        axios.post(this.$store.state.api + 'dislikecomment', {
          id: comment.id,
        }).then(response => {
          response.data.result;
        })
      }else {
        this.$store.state.notify.text = 'قبلا امتیاز دادید';
        this.$store.state.notify.is = true;
      }
    },



    send(ansId=null) {
      if(!this.loggedIn){
        this.$store.state.loginDialog=true;
      }else {
        if (this.$store.state.userId) {
          this.loading = true;
          axios.post(this.$store.state.api + 'addpostcomment', {
            postId: this.id,
            userId: this.$store.state.userId,
            ansId:ansId,
            rate: this.rate,
            text: this.text
          }).then(response => {
            this.loading = false;
            this.text = "";
            this.rate = 3;
            this.$store.state.notify.text = 'نظر ثبت شد پس از تایید مدیر نمایش داده میشود';
            this.$store.state.notify.is = true;
            this.items = response.data.data;
          })
        }
      }
    },
    deleteCm(id) {
      this.loading = true;
      axios.post(this.$store.state.api + 'delpostcomment', {
        id: id,
        postId: this.id,
        userId: this.$store.state.userId,
      }).then(response => {
        this.loading = false;
        this.$store.state.notify.text = 'نظر حذف شد';
        this.$store.state.notify.is = true;
        this.items = response.data.data;
      })
    }

    ,
    get(){
      axios.get(this.$store.state.api + 'postcomments?postId='+this.id+'&userId='+this.$store.state.userId).then(response => {
        this.items = response.data.data;
      })
    },
  },

  created() {
    this.get()
  },
}
</script>

<style scoped>

.menuItem {
  width: 300px;
  display: flex;
  justify-content: right;
  cursor: pointer;
  text-align: right;
  margin-top: 20px;
}

.singlecomment{
  border-bottom: 1px #ccc solid;
  margin-bottom: 10px;
  background-color: #1a1927;

}

.response{
  margin-right: 50px;
  border-radius: 10px;
  background-color: #202036;
  color: #fff;
}
.singlecomment:last-child{
  border-bottom: none;
}

.comments{
  background: #1a1927; /* fallback for old browsers */
  /*background: -webkit-linear-gradient(to right, #2f2f40, #191926); !* Chrome 10-25, Safari 5.1-6 *!
  background: linear-gradient(to right, #2f2f40, #191926); !* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ *!*/

  padding: 20px;
  color: #cccccc;
}
.menuIcon {
  height: 50px;
  margin: -5px 10px 10px 10px;
}

.items {
  position: relative;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  margin: 20px 15px;
}

.btn-service {
  position: absolute;
  bottom: -20px;
  left: 20px;
}

.menutext {
  display: inline-block;
}

.textfooter {
  display: block;
  font-size: 12px;
}

.deletecm{
  color: red;
  cursor: pointer;

}

.deletecm:hover{
  color: #670f0f;
  text-shadow: 0px 0px 1px #aa1515;
}

</style>